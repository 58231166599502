import React from 'react';
import vid2 from "../../assets/vid2.mp4";
import { Carousel, Typography, Button } from "@material-tailwind/react";
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';
import d2 from '../../assets/d2.jpeg';
import d3 from '../../assets/d3.jpeg';
import d4 from '../../assets/d4.png';

const Home = () => {

  return (
    <>
      <Navbar />
      <div className='relative'>
        <Carousel
          className=" w-[100vw] h-full overflow-hidden"  
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-black" : "w-4 bg-white"
                    }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          <div className="sticky  ">
            <video className=" min-h-screen max-w-[100vw] object-cover" autoPlay loop muted playsInline>
              <source src={vid2} type="video/mp4" />
            </video>
            <div className="absolute inset-0 grid h-full w-full left-0  items-center">
              <div className="w-3/4  flex justify-center ">
                <Typography
                  variant="h1"
                  color="white"
                  className=" "
                >
                  <h1
                    className='text-red-600 font-bold ml-4  text-[50px] mt-16'>
                    Helping You <br />
                    thrive and <br />
                    drive into the <br />future</h1>
                </Typography>
              </div>

              <div className="flex justify-center gap-2">
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12  "
                >
                  {/* It is not so much for its beauty that the forest makes a claim
              upon men&apos;s hearts, as for that subtle something, that quality
              of air that emanation from old trees, that so wonderfully changes
              and renews a weary spirit. */}
                </Typography>

              </div>
            </div>
          </div>
          <div className="relative   h-full w-full  items-center">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/5120x2880-dark-blue-solid-color-background.jpg/2560px-5120x2880-dark-blue-solid-color-background.jpg"
              alt=""
              className="min-h-screen  max-w-[100vw] object-cover"
            />
            <div className="absolute inset-0 grid  lg:items-center grid-cols-1 ">
              <div className=" lg:mt-16 mt-40">
                < h1
                  className="mb-4 text-3xl font-bold text-center md:text-4xl lg:ml-8 text-white lg:text-5xl"
                >
                  Meeting You Where You Are
                </h1>
                <div className="relative text-black h-full">
                  <div className='grid grid-cols-1 lg:grid-cols-3 lg:h-full lg:w-full '>
                      <div className='bg-white h-[45vh] mt-8 ml-16 w-96 rounded-tr-3xl'> 
                    <div className='  h-40 lg:h-full  relative'>
                      <img src={d2}
                        alt=""
                        className='sm:h-[30vh] h-[40%]  lg:w-[85%]  w-[60%]    text-center object-cover'
                      />
                      <div className="absolute      px-4 pb-4  text-center mb-40 w-full">
                        <h2 className="text-2xl font-bold ">Delivering Your Products</h2>
                        <h1 className='text-3xl font-bold '>
                          I want to get <br />
                          tires fast</h1>
                      </div>
                    </div>
                    </div>
                    <div className='bg-white h-[45vh] mt-8 ml-16 w-96 rounded-tr-3xl'> 
                    <div className=' h-40 lg:h-full  relative'>
                      <img src={d3}
                        alt=""
                        className='sm:h-[30vh] h-[40%]  lg:w-[85%]  w-[60%]    text-center object-cover'
                      />
                      <div className="absolute      px-4 pb-4  text-center mb-40 w-full">
                        <h2 className="text-2xl font-bold ">Empowering your Success</h2>
                        <h1 className='text-3xl font-bold'>I want to grow <br /> my business</h1>
                      </div>
                    </div>
                    </div>
                    <div className='bg-white h-[45vh] mt-8 ml-16 w-96 rounded-tr-3xl'> 
                    <div className='  h-40 lg:h-full relative'>
                      <img src={d4}
                        alt=""
                        className='sm:h-[30vh]  h-[40%]  lg:w-[85%]  w-[60%]    text-center object-cover'
                      />
                      <div className="absolute     px-4 pb-4 w-full text-center mb-40">
                        <h2 className="text-2xl font-bold">advancing your carrier</h2>
                        <h1 className='text-3xl font-bold'>I want to work <br /> at ITD</h1>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative  h-full w-full bg-black">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/5120x2880-dark-blue-solid-color-background.jpg/2560px-5120x2880-dark-blue-solid-color-background.jpg"
              alt=""
              className="min-h-screen max-w-[100vw] object-cover"
            />
            <div className="absolute inset-0 grid  h-full w-full items-end ">
              <div className="w-[83%] pl-12 pb-12 md:w-2/4 md:pb-20 lg:pl-32 ">
                <h1
                  className="mb-4 text-3xl md:text-4xl lg:text-5xl font-bold mt-32 text-white"
                >
                  fulfilling Your order is our top priority
                </h1>
                <Typography
                  variant="lead"
                  color="white"
                  className="mb-12 opacity-80"
                >
                  It is not so much for its beauty that the forest makes a claim
                  upon men&apos;s hearts, as for that subtle something, that quality
                  of air that emanation from old trees, that so wonderfully changes
                  and renews a weary spirit.
                </Typography>
                <div className="flex gap-4">
                  <Button className=' text-white text-xl font-bold bg-red-600 py-2'>
                    Distribution Network
                  </Button>
                  <Button className=' text-white text-xl font-bold bg-red-600 py-2 ' >
                    Our Products
                  </Button>
                </div>

              </div>
        
              <div className='flex justify-end lg:divide-x-2   '>
                <div className='gap-6 m-2 '>
                  <span className='font-bold text-3xl text-white '>15K</span>
                  <h1 className='text-white text-bold text-3xl'>active <br />products</h1>
                </div>
                <div className='gap-6 m-2 ml-4 lg:px-4'>
                  <span className='font-bold text-3xl text-white'>95K+</span>
                  <h1 className='text-white text-bold text-3xl'>dilevery <br /> points</h1>
                </div>
                <div className='gap-6 m-2 ml-4 lg:px-4'>
                  <span className='font-bold text-3xl text-white'>95M</span>
                  <h1 className='text-white text-bold text-3xl'>Units dileverd <br /> annuly</h1>
                </div>
              </div>
            </div>
          </div>

          <div className="relative   h-full w-full">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/5120x2880-dark-blue-solid-color-background.jpg/2560px-5120x2880-dark-blue-solid-color-background.jpg"
              alt=""
              className="min-h-screen  max-w-[100vw] object-cover"
            />

            <div className="absolute inset-0  grid lg:grid-cols-2  text-center items-center h-full w-full ">

              <div className='lg:w-80 w-[80%] ml-8 mt-20   bg-white shadow-2xl rounded-br-2xl  rounded-tl-2xl'>
                <h1 className='text-xl font-bold px-8 pt-8   '>After starting  a stocking new sizes based on ITD's recommendation engine, I inceased my same-day
                  sales by 15%, 3 of them are now in my top 5 best saller." </h1>
                <p className='text-red-500 font-bold text-3xl px-8 pb-8'>-ITD</p>
              </div>

              <div className="gap-4 p-2">
                <h1 className="text-3xl lg:text-[50px] font-bold text-white gap-8">
                  So you can <span className="text-red-500 mb-2">navigate</span> <br />  <br />the road ahead
                </h1>
                <p className="text-white text-xl mt-4">Run your business with a partner who's there to offer full-service <br /> support that positions you for success today, <br /> and helps you plan for tomorrow</p>
                <div className="gap-8 lg:flex justify-center p-4 mt-4">
                  <div>
                    <button className="text-2xl font-bold bg-red-600 text-white w-full rounded-tl-3xl rounded-br-3xl p-2 mb-4">What We Offer</button>
                  </div>
                  <div>
                    <button className="text-2xl font-bold bg-red-600 text-white w-full rounded-tl-3xl rounded-br-3xl p-2">Partner With Us</button>
                  </div>
                </div>
              </div>



            </div>

          </div>

        </Carousel>
      </div>

      <Footer />
    </>
  );
};

export default Home;
