import React, { useEffect, useState } from 'react';
import { FaBars } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import Logo1 from '../../assets/Logo1.png';
import { Link } from 'react-router-dom';


const Navbar = ({ isContactPage }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.addEventListener('scroll', handleScroll);
        }
    })
    const handlepartner = () => {

        window.location.href = '/PartnerUsPage';
    };

    return (
        <header className="w-full md:bg-transparent  fixed top-0 left-0 right-0 ">
            <nav className={` py-6 lg:px-14 px-4 bg-gray-300 sm:bg-black ${isSticky ? "sticky top-0 left-0 right-0 border-b text-blue-900 bg-black  duration-300" : " "}    `}>
                <div className="flex text-base gap-8 items-center justify-center sm:justify-between">
                    <a href="/" className='text-2xl '>  <img src={Logo1} alt='logo' className=' w-[87px] h-[50px] ' /></a>

                    <ul className=" md:flex   space-x-12 hidden ">
                        <li className="ml-4"  >
                            <Link to="/who" className={`text-xl font-bold cursor-pointer  hover:border-2 hover:bg-blue-900 rounded-t-md hover:px-2  text-white  ${isContactPage ? 'text-White' : 'text-blue'}`}>Who we are</Link>
                        </li>
                        <li className="ml-4"  >
                            <Link to="/what" className={`text-xl font-bold cursor-pointer  hover:border-2 hover:bg-blue-900 rounded-t-md hover:px-2 text-white  ${isContactPage ? 'text-White' : 'text-blue'}`}>What We Offer</Link>
                        </li>
                        <li className="ml-4"  >
                            <Link to="/whatgetthere" className={`text-xl font-bold cursor-pointer hover:border-2 hover:bg-blue-900 rounded-t-md hover:px-2  text-white  ${isContactPage ? 'text-White' : 'text-blue'}`}>How We Get There</Link>
                        </li>
                    </ul>
                    <div className="  space-x-12 hidden lg:flex  items-center  ">
                        <Link to="/LoginPage" className={`hidden lg:flex text-xl font-bold mr-4  hover:border-2  hover:bg-blue-900 rounded-t-md hover:px-2  text-white  ${isContactPage ? 'text-White' : 'text-blue'}`}>ITD Online Login</Link>

                        <button onClick={handlepartner} className="text-xl font-bold  hover:border-2   py-2 p-4 duration-300  rounded-t-md text-white hover:bg-blue-900">Partner With Us</button>
                    </div>

                </div>
                {/* nav items for mobile devices */}
                <div className='md:hidden top-10 absolute left-5  '>
                    <button
                        onClick={toggleMenu}
                        className='text-black focus:outline-none  focus:text-gray-500'>
                        {
                            isMenuOpen ? (<RxCross2 className="h-10 w-6 text-gray-500" />) : (<FaBars className="h-10 w-6 text-gray-500" />)
                        }
                    </button>

                </div>
                {/* nav items for mobile devices */}
                <div className={` h-full px-4 mt-24 py-7  bg-gray-600 ${isMenuOpen ? "block fixed top-0 right-0 left-0" : "hidden"}`}>
                    <ul className="  gap-16  ">
                        <li className="ml-4 border-b-2 p-2"  >
                            <Link to="/who" className="text-xl font-bold cursor-pointer    ">Who we are</Link>
                        </li>
                        <li className="ml-4 border-b-2 p-2"  >
                            <Link to="/what" className="text-xl font-bold cursor-pointer mt- ">What We Offer</Link>
                        </li>
                        <li className="ml-4 border-b-2 p-2"  >
                            <Link to="/whatgetthere" className="text-xl font-bold cursor-pointer   ">How We Get There</Link>

                        </li>
                         
                    </ul>
                    <div className=' bottom-0 left-0  mt-28'> 
                        <div className='ml-4 border-b-2 p-2'>
                        <Link to="/LoginPage" className={` lg:flex text-xl font-bold   hover:border-2  hover:bg-blue-900 rounded-t-md hover:px-2  text-black  ${isContactPage ? 'text-White' : 'text-blue'}`}>ITD Online Login</Link>
                        </div>
                        <div className='ml-4 border-b-2 p-2'>
                         <button onClick={handlepartner} className="text-xl font-bold  hover:border-2   py-2  duration-300  rounded-t-md text-black hover:bg-blue-900">Partner With Us</button>
                         </div>
                         </div>
                </div>
            </nav>
        </header>
    )
}

export default Navbar;