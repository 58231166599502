import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Footer1 from "../footer/footer1";
import Header1 from "../slider/Header1";

const Registration = () => {
    const [customerNumber, setCustomerNumber] = useState("");
    const [billingZipCode, setBillingZipCode] = useState("");
    const [email, setEmail] = useState("");
    const [contactName, setContactName] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [preferredCommunicationMethod, setPreferredCommunicationMethod] = useState("Email");
    const [requestedUserId, setRequestedUserId] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <><Header1 />
            <div className="container mx-auto mt-8 grid  lg:grid-cols-2">

                <div className="max-w-md m-6 ml-8">
                    <h6 className="text-1xl font-semibold mb-4">REGISTRATION</h6>
                    <p>Verify your existing account information to get access or sign up to become a partner with ITD.</p>
                    <div>Existing Account Holder</div>
                    <p>Have an existing ITD account and need access to Location? Fill out your customer information to get started.</p>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="customerNumber" className="block font-semibold mb-1">
                                Customer Number
                            </label>
                            <input
                                type="text"
                                id="customerNumber"
                                value={customerNumber}
                                onChange={(e) => setCustomerNumber(e.target.value)}
                                className="w-full border rounded-md py-2 px-3" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="billingZipCode" className="block font-semibold mb-1">
                                Billing Pin Code for This Account
                            </label>
                            <input
                                type="text"
                                id="billingZipCode"
                                value={billingZipCode}
                                onChange={(e) => setBillingZipCode(e.target.value)}
                                className="w-full border rounded-md py-2 px-3" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block font-semibold mb-1">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full border rounded-md py-2 px-3" />
                            <p className="text-sm text-gray-500 mt-1">How we use your email</p>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="contactName" className="block font-semibold mb-1">
                                Contact Name
                            </label>
                            <input
                                type="text"
                                id="contactName"
                                value={contactName}
                                onChange={(e) => setContactName(e.target.value)}
                                className="w-full border rounded-md py-2 px-3" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="phone" className="block font-semibold mb-1">
                                Phone
                            </label>
                            <input
                                type="text"
                                id="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="w-full border rounded-md py-2 px-3" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="fax" className="block font-semibold mb-1">
                                Fax
                            </label>
                            <input
                                type="text"
                                id="fax"
                                value={fax}
                                onChange={(e) => setFax(e.target.value)}
                                className="w-full border rounded-md py-2 px-3" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="preferredCommunicationMethod" className="block font-semibold mb-1">
                                Preferred Communication Method
                            </label>
                            <select
                                id="preferredCommunicationMethod"
                                value={preferredCommunicationMethod}
                                onChange={(e) => setPreferredCommunicationMethod(e.target.value)}
                                className="w-full border rounded-md py-2 px-3"
                            >
                                <option value="Email">Email</option>
                                <option value="Phone">Phone</option>
                                <option value="Fax">Fax</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="requestedUserId" className="block font-semibold mb-1">
                                Requested User ID
                            </label>
                            <input
                                type="text"
                                id="requestedUserId"
                                value={requestedUserId}
                                onChange={(e) => setRequestedUserId(e.target.value)}
                                className="w-full border rounded-md py-2 px-3" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block font-semibold mb-1">
                                Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full border rounded-md py-2 px-3" />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="confirmPassword" className="block font-semibold mb-1">
                                Re-enter Password
                            </label>
                            <input
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="w-full border rounded-md py-2 px-3" />
                        </div>
                        <div className="flex justify-center">
                            <button type="submit" className="bg-gray-500 hover:bg-blue-900 font-bold   text-white px-6 py-3 rounded-md mr-2">
                                SUBMIT
                            </button>
                            <button type="button" className="bg-gray-500 hover:bg-blue-900 font-bold text-white px-6 py-3 rounded-md ml-2">
                                CANCEL
                            </button>
                        </div>
                    </form>
                </div>
                {/* Right side content */}
                <div className="max-w-md h-[73%] p-4 bg-gray-200"  >
                    <h6 className="text-1xl font-semibold mb-4">Become a New ITD Customer</h6>
                    <p>Apply to become a retail tire and wheels partner with ITD. Click below to access our online application portal. Provide your legal business name and state, tell us about your business, and even sign up for credit.</p>
                    <button className="bg-gray-500 text-xs hover:bg-blue-900 font-bold text-white px-6 py-3 rounded-md mt-4 flex items-center justify-center">

                        START NEW PARTNER AGREEMENT
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                    </button>
                </div>
            </div>
            <Footer1 /></>
    );
};

export default Registration;
