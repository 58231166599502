import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import men from '../../assets/men.png'
import founder from '../../assets/founder.png';
import team from "../../assets/team.png";
import harmeet from '../../assets/harmeet.jpg';

const Leadership = () => {
    return (
        <>
            <Navbar />
            <div className="  h-screen mx-auto">
                <div className="relative">
                    <div className="absolute inset-0 bg-cover text-center bg-center">
                        <img src="https://images.pexels.com/photos/3954429/pexels-photo-3954429.jpeg?auto=compress&cs=tinysrgb&w=600"
                            alt=""
                            className=" w-full h-screen "
                        />
                    </div>
                    <div className="relative lg:ml-16  p-6   text-white  ga-8 ">
                        <div className="  mt-28 lg:mt-28">
                            <h3 className=" font-bold   ">Leadership</h3>
                            <h1 className="lg:text-[80px] font-bold text-[60px]  ">Navigating the road ahead</h1>
                            <p className='text-2xl mb-7 lg:mt-8'>
                                There are tricky curves to manage on the highway of inter-connected <br />
                                business. How is ITD transforming the organization? The answer is our <br />
                                people. Our best-in-class talent brings effective leadership to everything <br />
                                we do.
                            </p>

                        </div>


                        <div className="lg:flex  grid sm:grid-cols-2   grid-cols-1  h-screen">

                            <div className="flex  justify-center  sm:w-[30%] h-[90%] border-t   rounded-tr-3xl bg-white">
                                <div className=' shadow-md border-w-[50%] w-80 h-[90%] sm:h-[70%]  rounded-tr-3xl lg:mt-8 overflow-hidden'>
                                    <img src={harmeet}
                                        className='w-[80%] sm:ml-8 h-[60%]'
                                        alt="" />
                                    <div className='lg:ml-6 ml-4 lg:mt-8'>
                                        <h1 className='text-gray-800 font-bold text-xl'>Harmeet Singh Luthra </h1>
                                        <p className='text-2xl font-bold text-gray-900  mt-2'>President and Chief <br /> Executive Officer</p>
                                    </div>
                                </div>

                            </div>

                            <div className="flex justify-center   lg:w-[30%] h-[90%] border-t   rounded-tr-3xl bg-white">
                                <div className=' shadow-md border-w-[50%] w-80 h-[100%] sm:h-[70%]    rounded-tr-3xl  overflow-hidden'>
                                    <img src={team}
                                        className=' w-[90%] h-[60%] sm:ml-4'
                                        alt="" />
                                    <div className='lg:ml-6 ml-4 lg:mt-8 '>
                                        <h1 className='text-gray-800 font-bold text-xl'>Hena Chaudhary</h1>
                                        <p className='text-2xl font-bold text-gray-900  mt-2'>Chief Merchandising <br /> Officer</p>
                                    </div>
                                </div>

                            </div>


                            <div className="flex justify-center   lg:w-[30%] h-[90%] border-t   rounded-tr-3xl bg-white">
                                <div className=' shadow-md  border-w-[50%] w-80 h-[90%] sm:h-[70%]   rounded-tr-3xl lg:mt-8 overflow-hidden'>
                                    <img src={men}
                                        className=' w-[90%] h-[60%]'
                                        alt="" />
                                    <div className='lg:ml-6 ml-4 '>
                                        <h1 className='text-gray-800 font-bold text-xl'>Krishan Kant</h1>
                                        <p className='text-2xl font-bold text-gray-900 mb-8 '>Chief Supply Chain <br /> Officer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='flex justify-center lg:mt-2 mt-96 sm:mt-60 p-2'>
                    <div className=' mt-16  items-center'>
                        <h1 className='text-blue-950 font-bold text-3xl'> <span className='text-red-800 text-[50px]'>"</span>
                            A stronger supply chain, better-than-ever service, and <br />
                            smoother operations in every way make this an exciting <br />
                            time for ITD. We are evolving. We are adapting. Stay <br />
                            tuned.”
                        </h1>
                        <p className='text-red-700 font-bold'>—Chief Supply Chain Officer</p>
                    </div>
                </div>

                <div className="  sm:mt-20 flex ml-12 items-center    h-screen bg-blue-900 ">

                    <div className=" grid  sm:grid-cols-2 md:grid-cols-3 grid-cols-1  gap-8   w-full h-[100vh]   bg-white lg:rounded-br-[40%]">
                        <div className=' shadow-2xl border-w-[40%] w-80 h-[45vh] sm:h-[70vh]  rounded-tr-3xl sm:mt-8 overflow-hidden'>
                            <img src="https://images.pexels.com/photos/3778680/pexels-photo-3778680.jpeg?auto=compress&cs=tinysrgb&w=600"
                                className=' w-full  '
                                alt="" />
                            <div className='lg:ml-6 mt-4 p-2'>
                                <h1 className='text-gray-800 font-bold text-xl'>Rebecca Sinclair</h1>
                                <p className='text-3xl font-bold text-gray-900 mb-8 mt-4 '>Chief People & Corporate <br /> Affairs Officer</p>
                            </div>
                        </div>




                        {/* <div className='shadow-2xl border-w-[50%] w-80 h-[45vh]  sm:h-[70vh]   rounded-tr-3xl lg:mt-8 overflow-hidden'>
                            <img src="https://images.pexels.com/photos/3778680/pexels-photo-3778680.jpeg?auto=compress&cs=tinysrgb&w=600"
                                className=' w-full '
                                alt="" />
                            <div className='lg:ml-6 lg:mt-8 p-2  '>
                                <h1 className='text-gray-800 font-bold text-xl'>Ryan Walsh</h1>
                                <p className='text-3xl font-bold text-gray-900 mb-8 mt-4'>Chief Financial Officer </p>
                            </div>
                        </div> */}





                        <div className=' shadow-2xl border-w-[50%] w-80 h-[41vh] sm:h-[70vh]   rounded-tr-3xl sm:mt-8 overflow-hidden'>
                            <img src={founder}
                                className=' w-full h-56'
                                alt="" />
                            <div className='sm:ml-6 sm:mt-8  p-2 '>

                                <h1 className='text-gray-800 font-bold text-xl'>Som Gangwar</h1>
                                <p className='text-3xl font-bold text-gray-900 mb-8 '>Chief Information and Technology Officer</p>
                            </div>
                        </div>
                    </div>
                </div>

 
                   <div className=' mt-96 sm:mt-0'> 
                    <div className='  flex justify-center  bg-blue-900'>
                        <div className='items-center sm:mt-40 p-2'>
                            <h1 className='text-white font-bold text-[40px]'>
                                <span className='text-red-800 text-[50px]'>"</span>
                                Ultimately, sales and leadership are about
                                relationships. At ITD, our efforts on several fronts 
                                have improved our relationships across the board.
                                And we’ll continue to improve everything, from the 
                                way we manage accounts to every part of the service 
                                experience.
                            </h1>
                            <p className='text-red-700 font-bold'>—ITD President & CEO</p>
                        </div>
                    </div>
                    </div>

                     
                 


          <Footer/>


            </div>




        </>
    )
}

export default Leadership;