import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';




const OurTeam = () => {
  return (
    <>
      <Navbar />
      <div className="max-h-full mx-auto">
        <div className="relative">
          <div className="absolute inset-0 bg-cover text-center bg-center">
            <img src="https://images.pexels.com/photos/2244746/pexels-photo-2244746.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt=""
              className=" w-full h-[60%] "
            />
          </div>
          <div className="relative   p-6 text-white font-bold  ">
            <div className="max-w-2xl  mt-28 sm:mt-28  text-center">
              <h3 className=" font-bold ">Our Team</h3>
              <h1 className="lg:text-[80px] text-[60px]   ">#LifeatITD</h1>
              <p className='text-bold mb-7'> Be you, with us at ITD</p>

            </div>

            <div className="flex justify-center    h-screen">

              <div className="text-center lg:mt-20 mt-20  lg:w-[80%] border-t   border-b-0 border-l-0 border-r-0 rounded-r-3xl bg-white">
                <h1 className="text-xl font-bold text-red-700 mb-4 mt-4 ">How we Show Up</h1>
                <p className="text-[50px] text-gray-800 mb-4">An unwavering dedication to deliver <br /> excellence at  <span className='text-bold tex-xl text-red-700'> ITD</span> </p>
                <p className='text-gray-700 mb-4'>We go to great lengths to hire great people. Not just because we’re hiring experts, but <br />
                  because we know that creating a place where everyone feels like they belong is <br />
                  essential to driving a truly people-first, connected culture.
                </p>
                <button className="bg-red-500 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
                  View Open Positions
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* ugeudhie */}
      <div className='text-center justify-center p-2'>

        <h1 className="text-xl font-bold text-red-700 mb-4 mt-4 ">How we roll</h1>
        <p className="text-[50px] font-bold text-gray-800 mb-4">Creating a place where people   <br /> feel like  <span className='text-bold tex-xl text-red-700'> they belong</span> </p>
        <p className='text-gray-700 text-xl mb-4'>
          While global trends and technology are giving people more choice in <br />
          automotive services, the industry has to continually adapt. For independent <br />
          dealers, it has become increasingly challenging to keep pace, let alone <br />
          compete. We’ll help you navigate the ever-changing landscape.
        </p>
      </div>
      {/* ygudruiifi */}
      <div className=' lg:mt-16 p-2 '>
        <div className='lg:flex  justify-center mb-8'>
           <div className=' '>
           <div className='bg-blue-900 sm:w-60 w-full h-40 sm:h-60 text-center mt-16  rounded-tr-3xl  rounded-bl-3xl'>
            <h1 className='mt-16 text-white font-bold sm:text-2xl  '> Purpose</h1>
            <p className='text-white '>To help our customers <br /> thrive and drive into the <br /> future.</p>
            </div>
            <div className='   text-center    '>
              <img src="https://images.pexels.com/photos/5589376/pexels-photo-5589376.jpeg?auto=compress&cs=tinysrgb&w=600"
                alt=""
                className='rounded-tl-3xl rounded-br-3xl w-full sm:w-60 h-96 sm:h-72' />
            </div>
           
           </div>
          <div className=''>
          <div>
            <img src="https://images.pexels.com/photos/3122801/pexels-photo-3122801.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt=""
              className='sm:h-72 sm:w-64 w-full h-72 rounded-tl-3xl rounded-br-3xl'
            />
              </div>
            <div className='bg-blue-900 sm:w-64 sm:h-60 h-40 w-full text-center    rounded-tr-3xl  rounded-bl-3xl'>
              <h1 className=' text-white font-bold text-2xl'> Vision</h1>
              <p className='text-white '>To be the leading <br/>tyre distributor <br/>in the market, <br/>recognised for our<br/> quality products,<br/> innovative solutions, <br/>and customer-centric <br/>approach.</p>
            </div>
         
          </div>
          <div>
             <div className='lg:mt-8 mb-16'>
            <img src="https://images.pexels.com/photos/1463112/pexels-photo-1463112.jpeg?auto=compress&cs=tinysrgb&w=600"
              alt=""
              className='sm:w-[99%] md:h-[40vh] w-full h-72 rounded-tl-3xl rounded-br-3xl'
            />
            <div className='bg-blue-900 sm:w-80 sm:h-64 w-full h-60 text-center    rounded-tr-3xl  rounded-bl-3xl'>
              <h1 className=' text-center text-white font-bold text-2xl'> Values</h1>
              <p className='text-white '>We share the responsibility for <br />the wellbeing of our company,<br /> our associates, and our <br />customers.</p>
            </div>
          </div>
          </div>
        </div>
      </div>

      {/* hdeid */}
      <div className=" flex justify-center    h-screen">

        <div className=" grid sm:grid-cols-2 grid-cols-1 lg:mt-20 mt-20  lg:w-[90%]  mb-8 shadow-2xl  rounded-tl-3xl  rounded-r-3xl ">

          <div className='flex    items-center  p-4'>
           
            <div className=''>
              <h1 className='font-bold text-3xl mb-8 text-blue-900'>Innovative</h1>
              <h3 className='font-bold text-xl text-blue-900 mb-8'> We encourage new ideas</h3>
              <p className='text-lg mb-8 text-gray-700'> We find people who add to our culture, not just fit in. <br />
                We look for diverse backgrounds, unique experiences,
                and new perspectives that will move us forward. 
                Whether you're part of our executive leadership
                team or an associate in the field, we embrace insights 
                that drive our business forward.</p>

              <button className='bg-red-700 text-white font-bold p-2 rounded-sm'>Meet Our Leadership</button>

            </div>
          </div>
          <div className='flex items-center  ml-12 '>
            <div className='bg-blue-900  '>

            </div>
            <div className='' >
              <img src="https://engmag.in/wp-content/uploads/2022/02/Technological-Advancements-in-the-Tire-Industry.jpg"
                alt=""
                className='w-[88%] rounded-2xl h-[70%] '
              />
            </div>
          </div>
        </div>
      </div>
      {/* uguergry */}
      <div className=''> 
      <div className='text-center justify-center p-2'>

        <h1 className="text-xl font-bold text-red-700 mb-4 mt-4 ">Where you fit in</h1>
        <p className="text-[40px] font-bold text-gray-800 mb-4">No matter where you are or what role <br />you play, you can <span className='text-bold tex-xl text-red-700'> build a <br /> future</span>with ITD </p>
        
      </div>
        <div className='grid sm:grid-cols-3 grid-cols-1 gap-4 mb-8 p-2'>
           <div className='bg-blue-400 rounded-tr-[10%] rounded-bl-3xl flex justify-center'>
             <img src="https://media.istockphoto.com/id/1288587538/photo/cheerful-auto-mechanic-worker-holding-a-car-tire-and-showing-thumbs-up.jpg?s=612x612&w=0&k=20&c=reOpOHkRji_YdDDLrnXOO1KWxbidsA6_BJGkWDROY0Q=" 
             alt=""
             className='  h-[90%] mt-8 rounded-tr-3xl rounded-bl-3xl'
             />
           </div>
           <div className='bg-cyan-400 rounded-tr-[10%]  rounded-bl-3xl flex justify-center'>
              <img src="https://media.istockphoto.com/id/1194486380/photo/auto-mechanic-putting-car-tires-in-a-pile.jpg?s=612x612&w=0&k=20&c=-6aDylpvQgq3USiAQKQseU3eYsBc94B4XWPY9QXeldE=" 
              alt=""
              className='h-[90%] mt-8 rounded-tr-3xl rounded-bl-3xl'
              />
           </div>
           <div className='bg-blue-900 rounded-tr-[10%] rounded-bl-3xl flex justify-center'>
              <img src="https://media.istockphoto.com/id/452586173/photo/confident-mechanic-with-wrench-sitting-on-tire.jpg?s=612x612&w=0&k=20&c=Rw7-ojBmg7Cy37Prj01KYUqT-pQHBJUVqvePGSjYLNU=" 
              alt=""
              className='h-[90%] mt-8 rounded-tr-3xl rounded-bl-3xl'
              />
           </div>
        </div>
      </div>
      {/* edgeyude */}
      <div className='bg-blue-950  min-h-96 mb-20  '>
        <div className='grid sm:grid-cols-2 grid-cols-1 p-2 rounded-tl-[30%] w-full h-[80vh] bg-white items-center '>
           <div className=' text-center'>
             <h1 className='font-bold text-blue-900 text-2xl'>
              <span className='h-28 text-red-600'>"</span>
             Our connected culture initiative fuels curiosity 
             and fosters a culture of continuous learning, 
             which  we believe also translates into increased 
             resilience for ITD.”

             </h1>
             <h2 className='text-red-700 font-bold'>-Chief People and Corporate Affairs Officer</h2>
           </div>
           <div className='flex justify-center'>
            <img src="https://media.istockphoto.com/id/1140573923/photo/tires-and-wheels-inspection.jpg?s=612x612&w=0&k=20&c=q557ZMJPQ_vQf1LEhtRLyUMptl8ivoeLVUkX4mmqQBE="
             alt=""
             className='w-[80%]  rounded-3xl'
             />
           </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default OurTeam;