import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

function PrivacyPolicy() {
  return (
    <>
      <Navbar isContactPage={true} />
      <div className="container mx-auto px-4 pt-40 pb-16 flex">
        <div className="w-full ">
          <h1 className="text-6xl leading-5.125rem text-black font-bold mb-6">ITD Privacy Policy</h1>
         <ol className="' text-black  dark:text-black' pl-6 mb-8">
            {/* <li> */}<p>&nbsp;</p>
              <h1 className=" text-2xl font-bold mb-4">1. Introduction</h1><p>&nbsp;</p>
              <div className="font-normal text-base leading-normal text-black tracking-normal mt-3 ">
              <p>Indian Tire Distributors, Inc. (“ITD”, “we”, or “us”) respects your privacy and is committed to protecting it through our compliance with this policy. This policy describes the types of information we may collect from you or that you may provide when you visit the website <a href='/' className='font-bold'>www.ITD.com</a> or any of its sub or micro-sites, including any content, functionality and services offered on or through such website or its apps (the “Website”) and our practices for collecting, using, maintaining, protecting and disclosing that information.</p>

          <h6 className='font-bold'>This policy applies to information we collect:</h6>  <p>&nbsp;</p>
          <li>On this Website.</li>
          <li>In e-mail, text and other electronic messages between you and us</li>&nbsp;
          <h6 className='font-bold'>This policy does not apply to information collected by:</h6>  &nbsp;
          <li>
            Us offline or through any other means not expressly stated in this policy, including on any other website operated by ITD or any third party (including our affiliates and subsidiaries); or
          </li>
          <li>
            any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Website.
          </li>&nbsp;
          <p>
          Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, you should not access or use our Website.  By accessing or using our Website, you agree to this privacy policy. This policy may change from time to time (see the “Changes to our Privacy Policy” section below). Your continued use of the Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
          </p>&nbsp;
          <h1 className=" text-2xl font-bold mb-4">2. Children Under the Age of 13</h1><p>&nbsp;</p>
          <p>Our Website is not intended for children under 13 years of age.  No one under age 13 may provide any information to or on Website. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on or through the Website or on or through any of their features/register on the Website, use any of the interactive or public comment features of the Website, or provide any information about yourself to us, including your name, address, telephone number, e-mail address or user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at <a href='/' className='font-bold'>info@ITD-us.com.</a></p>&nbsp;
          <h1 className=" text-2xl font-bold mb-4">3. Information We Collect About You and How We Collect It</h1><p>&nbsp;</p>
         <p>
         The categories of personal information we collect depend on how you interact with us, our Website, and the requirements of applicable law.  We collect information that you provide to us, information we obtain automatically when you use our Website, and information from other sources such as third-party services and organizations, as described below.   For residents of certain states, additional disclosures about the information we collect is available in our supplemental disclosure below.
         </p>
          </div>
          </ol>
        </div>

    </div>
              <Footer />
    </>
  )
}

export default PrivacyPolicy;