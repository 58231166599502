import React from 'react';
import logo3 from '../../assets/logo3.png';
import partner1 from '../../assets/partner1.jpeg';
import house from '../../assets/house.png';

const PartnerUsPage = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${partner1})`,
    backgroundSize: 'cover',
    width: '100vw',
    height: '50vh',
  };
  const handlelogin = () =>{
    window.location.href = '/LoginPage';
}
const handlePatner = () =>{
  window.location.href = '/contact';
}

  return (
    <>
      <div className="mr-8 flex p-4 w-full bg-white items-center">
        <a href="/" className="block">
          <img src={logo3} alt='Icon' className='h-6 w-26' />
        </a>
      </div>
      <div className="max-w-full max-h-full mx-auto">
        <div className="relative">
          <div className="absolute inset-0 bg-cover bg-center" style={backgroundImageStyle}></div>
          <div className="relative z-10 p-6 text-white font-bold  ">
            <h1 className="text-6xl mt-auto font-bold mb-4 lg:ml-16 text-gray-200">The ITD Advantage</h1>
            <p className="mb-6 text-base font-poppins lg:ml-20">Let's grow your business together.</p>
            <p className="mb-6 text-base font-poppins lg:ml-20">
              If you're interested in becoming a retail tires and wheels partner,<br /> select an option below.
              If you have any questions,<br /> please contact us via Email, or call us at....
              <a href="tel:8800666339" className="text-blue-800 ">+91 880-066-6339</a>.
            </p>

            <div className="lg:flex    items-center justify-evenly">
              <div className="  bg-white text-black lg:flex border-2 border-gray-300  p-8 items-center px-4 rounded-xl">
                <div className='flex justify-center'>
                <img src="https://cdn.pixabay.com/photo/2024/02/02/09/12/ai-generated-8547724_1280.jpg" alt='' className='h-48 w-48 mr-4  ' />
                </div>
                <div>
                  <h2 className="text-2xl font-bold mb-2">Begin Your Partnership</h2>
                  <p>Complete our quick partner application.</p>
                  <button className="bg-blue-900 hover:bg-blue-900 text-white font-bold p-2 w-full rounded-xl mt-6"  onClick={handlePatner}>PARTNER WITH US</button>
                </div>
              </div>

              <div className="  bg-white text-black border-2 border-gray-300  mt-4 p-8 lg:flex items-center px-4 rounded-xl">
                <div className='flex justify-center'>
                <img src="https://cdn.pixabay.com/photo/2024/02/02/09/12/ai-generated-8547729_1280.jpg" alt='' className='h-48 w-48 mr-4' />
                </div>
                <div className=''>
                  <h2 className="text-2xl font-bold mb-2">Returning Partner?</h2>
                  <p>Continue where you left off with or <br /> view the status of your application.</p>
                  <button className="bg-blue-900 hover:bg-blue-900 text-white font-bold  p-2 w-full rounded-xl mt-6" onClick={handlelogin}>
                    <i className="fas fa-arrow-left mr-2"></i> LOGIN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-6 flex items-center">
          <img src={house} alt='House Icon' className='h-8 w-28 bg-transparent mr-4' />
          <div>
            <h2 className="text-2xl font-bold mb-2">Don't Have a Retail Tire Business?</h2>
            <p>ITD has several great programs for consumers and contractors working from home.</p>
            <a href="/learn" className="text-blue-900 font-bold">Learn More</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerUsPage;
