import React from 'react';
import logo3 from '../../assets/logo3.png';

const Footer1 = () => {
  return (
    <footer className="  border-t-2 mt-8  p-4 text-left">
  <div className=" justify-between items-center">
    <img src={logo3} alt="Radius Logo" className="h-8"/>
    <p className="text-left mt-4 text-black">© 2024 Indian Tire Distributors, Inc. All Rights Reserved.</p>
  </div>
</footer>
  );
};

export default Footer1;
