import React from 'react';
import Logo1 from '../../assets/Logo1.png'



const Header1 = () => {
  return (
    <div className='bg-black border-b-2 p-4'>
        <a href="/" className="block">
    <img src={Logo1} alt='logo' className='bg-transparent' style={{ width: '7rem', height: '3rem' }} />
    </a>
    </div>
  );
};

export default Header1;
