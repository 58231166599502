import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WhatWeGet from './components/whar we get there/WhatWeGet';
import Home from './components/home/Home';
import WhoWeAre from './components/who we are/WhoWeAre';
import WhatWeOffer from './components/whatweoffer/WhatWeOffer';
import LoginPage from './components/login/LoginPage';
import Registration from './components/login/Registration';
import PartnerUsPage from './components/partner/PartnerUs';
import ContactUs from './components/contact/ContactUs';
import TermsCondition from './components/policy/TermsCondition';
import './App.css'
import OurTeam from './components/who we are/OurTeam';
import Diversity from './components/who we are/Diversity';
import Leadership from './components/who we are/Leadership';
// import NewsIdea from './components/who we are/NewsIdea';
import Suppliers from './components/policy/Suppliers';
import PrivacyPolicy from './components/policy/PrivacyPolicy';
// import Wheel from './components/whatweoffer/wheel';
import Tires from './components/whatweoffer/Tires';
import ITDSuppliers from './components/whatweoffer/ITDSupplies';
import Grow from './components/whatweoffer/Grow';
import Products from './components/whatweoffer/Products';
import Shop from './components/whatweoffer/Shop';
import Reward from './components/whatweoffer/Reward';
import GivinBack from './components/who we are/GivinBack';
import Careers from './components/who we are/Careers';
import SocialResponsibility from './components/who we are/SocialResponsibility';
import Sustainability from './components/who we are/Sustainability';
import Location from './components/who we are/Location';
// import Benifits from './components/who we are/Benifits';


const App = () => {

  
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/whatgetthere" element={<WhatWeGet />} />
        {/* <Route path='/wheel' element={<Wheel/>} /> */}
        <Route path="/LoginPage" element={<LoginPage />} />
        <Route path='/Registration' element={<Registration />} />
        <Route path='/PartnerUsPage' element={<PartnerUsPage />} />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/who' element={<WhoWeAre />} />
        <Route path='/what' element={<WhatWeOffer />} />
        <Route path='/Suppliers' element={<Suppliers />} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
        <Route path='/TermsCondition' element={<TermsCondition />} />
        <Route path='/ourteam' element={<OurTeam/>}/>
        <Route path='/diversity' element={<Diversity/>} />
        <Route path='/Location' element={<Location/>} />
        <Route path='/Tires' element={<Tires/>} />
        <Route path='/ITDSuppliers' element={<ITDSuppliers/>} />
        <Route path='/Grow' element={<Grow/>} />
        <Route path='/Shop' element={<Shop/>} />
        <Route path='/product' element={<Products/>}/>
        <Route path='/GivinBack' element={<GivinBack/>} />
        {/* <Route path='/benifit' element={<Benifits/>} /> */}
        <Route path='/careers' element={<Careers/>} />
        <Route path='/Reward' element={<Reward/>} />
        <Route path='/leader' element={<Leadership/>} />
        {/* <Route path='/news' element={<NewsIdea/>}/> */}
        <Route path='/social' element={<SocialResponsibility/>}/>
        <Route path='/sustainability' element={<Sustainability/>}/>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
