import React from 'react';
// import Header from '../slider/Header';
import pic3 from '../../assets/pic3.jpeg';
import Footer from '../footer/Footer';
import Navbar from '../navbar/Navbar';

function ContactUs() {


  return (
    <>
      <Navbar isContactPage={true} />
      <h6 className="px-8 py-24">Home</h6>
      <div className="container mx-auto px-4  pb-16 flex">
        <div className="w-1/2 pr-8">
          <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
          <h2 className="font-bold text-3xl mb-4 text-black">Here when you <span className="text-red-500 font-bold text-3xl mb-4">need a &nbsp; hand.</span></h2>
          <p>&nbsp;
            <p className="mb-4 font-normal text-base leading-normal text-black tracking-normal mt-3">What’s on your mind? If you’d like to work with us or for us, we want to hear from you. Get in touch with our team today.</p>
          </p>
          <p>&nbsp;
            <a href="/become-customer" className="text-black-200"><p>&nbsp;</p><strong className="text-black font-semibold">Click Here</strong> to become an ITD customer.</a>
          </p>

          <a href="/become-customer" className="text-black-200"><p>&nbsp;</p><strong className="text-black font-semibold">Click Here</strong> to check the status of your job application.</a>
          {/* <div className="blue-background relative  bg-blue-900"> */}
          <div className="red-line absolute left-0 top-0 w-full h-2 bg-red-500"></div>
          <img src={pic3} alt='' style={{ height: '38rem', width: 'auto', paddingTop: '7rem' }} className="z-10" />
          {/* </div> */}
        </div>

        <div className="w-1/2">
          <form className="max-w-lg">
            <div className="mb-4">
              <label htmlFor="name" className="block font-bold mb-2">Name</label>
              <input type="text" id="name" name="name" className="border border-gray-400 px-4 py-2 w-full" placeholder="Please enter name" />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block font-bold mb-2">Email address</label>
              <input type="email" id="email" name="email" className="border border-gray-400 px-4 py-2 w-full" placeholder="example@example.com" />
            </div>
            <div className="mb-4">
              <label htmlFor="phone" className="block font-bold mb-2">Phone number</label>
              <input type="tel" id="phone" name="phone" className="border border-gray-400 px-4 py-2 w-full" placeholder="Enter your phone number" />
            </div>
            <div className="mb-4">
              <label htmlFor="zip" className="block font-bold mb-2">Pin code</label>
              <input type="text" id="zip" name="zip" className="border border-gray-400 px-4 py-2 w-full" placeholder="Enter your Pin code" />
            </div>
            <div className="mb-4">
              <label htmlFor="reason" className="block font-bold mb-2">Reason for contact</label>
              <select id="reason" name="reason" className="border border-gray-400 px-4 py-2 w-full">
                <option value="" disabled selected>- Select -</option>
                <option value="work">Interested in working with us</option>
                <option value="job">Job application status</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block font-bold mb-2">Message</label>
              <textarea id="message" name="message" rows="4" className="border border-gray-400 px-4 py-2 w-full" placeholder="Enter your message"></textarea>
            </div>
            <div className="mb-4">
              <label htmlFor="captcha" className="block font-bold mb-2">Image CAPTCHA</label>
              <div className="flex items-center">
                <input type="text" id="captcha" name="captcha" className="border border-gray-400 px-4 py-2 flex-1 mr-2" placeholder="Enter CAPTCHA" />
                <button className="bg-black hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">Get new captcha!</button>
              </div>
              <img src="/captcha-image.jpg" alt="CAPTCHA" className="mt-2" />
            </div>
            <button type="submit" className="bg-black hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">Submit</button>
          </form>
        </div>

      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
