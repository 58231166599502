
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import pic2 from '../../assets/pic2.jpg';
 

const WhatWeOffer = () => {
  return (<>   
  <Navbar/>  
  <div className='grid sm:grid-cols-2 grid-cols-1 absolute bg-gray-200  sm:h-full     top-0 left-0 w-full '>

<div className='items-center ml-4 md:ml-16 mt-28 '>
<img src={pic2} alt="" className='sm:w-[40vw] w-[92vw]  h-[55vh] sm:h-[70vh] rounded-tr-3xl' /> 
</div>
<div className=' sm:mt-40 mt-20  gap-8'>
    <h2 className='text-xl underline ml-4'>What We Offer</h2>
    <div className='grid sm:grid-cols-2  grid-cols-2 mt-8 gap-8'>
        <div className='ml-4'>

  
            <Link to="/Location" className='font-bold text-[30px] hover:text-blue-900 hover:underline'>Location</Link>
            <div className=' flex flex-col'>
            <Link to="/Shop" className='mt-4  hover:text-blue-900 hover:underline'>Shop</Link>
            <Link to="/Grow" className='mt-4  hover:text-blue-900 hover:underline'>Grow</Link>
            <Link to="/Reward" className='mt-4  hover:text-blue-900 hover:underline mb-4'>Reward</Link>
      
            </div>
             
        </div>
        <div className='ml-2'>
        <Link to="/product" className='font-bold text-[30px] hover:text-blue-900 hover:underline'>Products</Link>
        <div className=' flex flex-col'>
            <Link to="/ITDSuppliers" className='mt-4  hover:text-blue-900 hover:underline'>Supplies</Link>
            <Link to="/Tires" className='mt-4  hover:text-blue-900 hover:underline'>Tires</Link>
            {/* <Link to="/wheel" className='mt-4  hover:text-blue-900 hover:underline'>Wheels</Link> */}
            </div>
          
        </div>
        </div>
        </div>
        
    </div>
     
     </>

  )
}

export default WhatWeOffer;