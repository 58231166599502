import React from "react";
import sideImage from '../../assets/sideImage.svg';
import Footer1 from "../footer/footer1";
import Header1 from "../slider/Header1";
import Logo1 from '../../assets/Logo1.png';

const LoginPage = () => {
    const redirectToRegistration = () => {
        window.location.href = '/Registration';
    };
    return (
        <>
            <div>
                <Header1 style={{ padding: '2rem' }} />
            </div>
            <div className="container mx-auto  sm:flex justify-between">

                <div className="max-w-md  mt-8 p-4 rounded-xl shadow-2xl">
                    <h1 className="text-3xl text-center max-w-14rem w-14rem font-semibold mb-4">Welcome</h1>
                    <div className="mb-4">
                        <label htmlFor="userId" className="block font-semibold mb-1">User ID</label>
                        <input type="text" id="userId" className="w-full border rounded-md py-2 px-3" />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="rememberUserId" className="block font-semibold mb-1">
                            <input type="checkbox" id="rememberUserId" className="mr-2" />
                            Remember my User ID
                        </label>
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block font-semibold mb-1">Password (Case Sensitive)</label>
                        <input type="password" id="password" className="w-full border rounded-md py-2 px-3" />
                    </div>
                    <button className="bg-black text-white px-6 py-3 rounded-md mr-2 hover:bg-blue-900">LOG IN</button>
                    <div>
                    <a href="/forgot" className="text-blue-900">Forgot ID?</a>
                    <span className="mx-2">|</span>
                    <a href="/forgotPassword" className="text-blue-900">Forgot password?</a>
                    </div>
                    <p className="mt-4">You need an active ITD customer account before you can register to place orders on Location.</p>
                    <a href="/singup" className="text-black-500 font-bold block mt-4">Sign up to become a partner with ITD</a>
                    <button onClick={redirectToRegistration} className="border border-blue-900 text-white bg-black  p-2 rounded-md hover:bg-blue-900 block mt-2">
                        REGISTER WITH ITD
                    </button>
                </div>
                <div className="  mt-8    flex justify-between">
                    {/* Left div */}
                    <div className=" text-white p-6 rounded-t-lg flex" style={{ backgroundColor: '#000000' }}>
                        <div className="wrapper text-white mb-2 mt-4">
                            <img src={Logo1} alt='logo' className='bg-transparent' style={{ width: '5rem', height: '3rem' }} />
                            <h6 className="text-3xl font-semibold mb-4">The new Location experience is here!</h6>
                            <p>The wait is over. Log in now to see the new and improved Location.</p>
                            <p>We’ve updated Location to provide faster search, enhanced product listings, improved check-out, easier order history, and more.</p>
                            <h6>Embrace the new Location</h6>
                            <p>Please be sure to bookmark this page for easier navigation back to this site..</p>
                            <p>It's a free service from Indian Tire Distributors to help you grow your business.</p>
                            <p>Having trouble logging in? Call Customer Service at +91 880-066-6339 for assistance.</p>
                            <p>Our Customer Service Team is available:</p>
                            <ul>
                                <li>Monday–Saturday 8:00 AM to 8:00 PM IST</li>
                            </ul>
                            <button className="border border-blue-900 font-bold text-white p-2 bg-blue-900 block mt-4">Learn More About Location</button>
                        </div>
                        <div className="image-container w-[35%] px-305 py-8">
                            <img src={sideImage} alt="" />
                        </div>
                    </div>

                </div>
            </div>
            <Footer1 />
        </>
    );
};

export default LoginPage;
